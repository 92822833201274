<template>
  <div>
    <CRow>
      <CCol lg="6">
        <CCardBody>
          <CDataTable
                  :items="apiDatas"
                  :fields="fields"
                  column-filter
                  table-filter
                  items-per-page-select
                  :items-per-page="50"
                  sorter
                  pagination
                  hover
                  clickable-rows
                  @row-clicked="rowClicked"
                  @page-change="pageChange"
          >
            <!--<template #actions="{ item }">
              <td>
                <CButton @click="removeLine(item)" color="primary">Удалить</CButton>
              </td>
            </template>-->
          </CDataTable>
        </CCardBody>
      </CCol>
      <CCol lg="6">
        <CCard>
          <CCardHeader>
            <strong>Добавить Оператора</strong>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="createOperator()">
              <CInput
                      type="hidden"
                      name="action"
                      v-model="form.action"
                      required
              />
              <CInput
                      label="Имя оператора"
                      v-model="form.operator_name"
                      required
              />
                <CInput
                        label="Логин оператора"
                        v-model="form.operator_login"
                        required
                />
              <CInput
                      type="tel"
                      label="Телефон оператора"
                      v-model="form.operator_phone"
                      required
              />
              <CInput
                      type="password"
                      label="Пароль"
                      v-model="form.password"
                      required
              />
              <CButton type="submit" size="sm" color="primary"><CIcon name="cil-check-circle"/> Добавить</CButton>
            </CForm>
          </CCardBody>
          <CCardFooter>
          </CCardFooter>
        </CCard>
      </CCol>

    </CRow>
  </div>
</template>

<script>
  import axios from 'axios'


  const fields = [
    {
      key: "name",
      label: "Имя",
      _style: "min-width:100%;"
    },
    {
      key: "phone",
      label: "Телефон",
      _style: "min-width:100%;"
    }
  ];

  export default {
    name: 'Operators',
    data: function() {
      return {
        visibleLiveDemo: false,
        apiDatas: [],
        grsDatas: [],
        operatorDatas: [],
        tableFields: ['id', 'name', 'phone'],
        form: {
          action: 'add_operator',
          operator_name: "",
          operator_login: "",
          operator_phone: "",
          password: ""
        },
        fields,
        itemActive: 0,
      }
    },
    watch: {
        $route: {
            immediate: true,
            handler (route) {
                if (route.query && route.query.page) {
                    this.activePage = Number(route.query.page)
                }
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        to.meta.oid = this.itemActive;
        next()
    },
    methods: {
      getDataFromApi () {
        axios
          .get(`${axios.defaults.baseURL}?action=get_operators`)
          .then(response => (this.apiDatas = response.data))
      },
      createOperator () {
        //TODO: `После добавления запрашивать только последнего добавленного`
        let bodyFormData = new FormData();

        bodyFormData.append('action', this.form.action);
        bodyFormData.append('name', this.form.operator_name);
        bodyFormData.append('login', this.form.operator_login);
        bodyFormData.append('phone', this.form.operator_phone);
        bodyFormData.append('password', this.form.password);

        console.log(bodyFormData)

        axios({
          method: "post",
          url: `${axios.defaults.baseURL}`,
          data: bodyFormData
        })
          .then((res) => {
            this.form.operator_name = "";
            this.form.operator_login = "";
            this.form.operator_phone = "";
            this.form.password = "";
            //Perform Success Action
            this.getDataFromApi()
          })
          .catch((error) => {
            console.error(error)
            // error.response.status Check status code
          }).finally(() => {
          // this.getDataFromApi()
        });
      },
      rowClicked (item, index) {
          this.itemActive = item.id;
          this.$router.push({path: `/operators/${index}`})
      },
      pageChange (val) {
          this.$router.push({ query: { page: val }})
      },
      removeLine (item) {

        let bodyFormData = new FormData();

        bodyFormData.append('action', "remove_operator");
        bodyFormData.append('id', item.id);

        axios({
          method: "post",
          url: `${axios.defaults.baseURL}`,
          data: bodyFormData
        })
          .then(() => {
            //Perform Success Action
            this.getDataFromApi()
          })
          .catch((error) => {
            console.error(error)
            // error.response.status Check status code
          }).finally(() => {
          // this.getDataFromApi()
        });
      },
    },
    mounted() {
      axios
        .get(`${axios.defaults.baseURL}?action=get_operators`)
        .then(response => (this.apiDatas = response.data));
    }
  }
</script>
